import React from "react"
import P from "../../core/P"
import H3 from "../../core/H3"
import Icon from "../../core/Icon"

export const ImageWithPoints = ({ feature, size, theme }) => {
  const SectionA = e => (
    <div className="flex flex-col h-full">
      {" "}
      {/* Ensure card content stretches to full height */}
      {e.fontello && (
        <Icon
          iconName={e.fontello}
          className="text-left flex justify-start pb-5 mx-0"
          theme={theme}
        />
      )}
      {e.icon && (
        <div className={`${e.title ? "pb-5" : " "} h-52`}>
          <div className="w-full h-full rounded-3xl text-white overflow-hidden">
            <img
              src={e.icon?.mediaItemUrl}
              loading="lazy"
              height={e.icon?.mediaDetails?.height}
              width={e.icon?.mediaDetails?.width}
              alt={e.icon?.altText ? e.icon?.altText : e.title}
              // title={e.title}
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      )}
      {e.title && (
        <H3
          title={e.title}
          className={`font-bold text-left text-shark-400 ${
            e.description ? "pb-5" : " "
          }`}
          textSize="text-lg"
        />
      )}
      {e.description && (
        <P title={e.description} className={`text-left text-shark-400`} noPad />
      )}
      {e.bulletpoints && e.bulletpoints.length > 0 && (
        <div
          className="bullet-container mt-auto "
          style={{
            backgroundColor: "rgba(196, 199, 206, 0.2)",
            borderRadius: "8px",
          }}
        >
          {" "}
          {/* Push bullet points to the bottom */}
          <ul className="list-disc pl-5 space-y-2 mt-4 text-shark-400">
            {e.bulletpoints.map((point, idx) => (
              <li key={idx} className="text-left">
                <span dangerouslySetInnerHTML={{ __html: point.subpoints }} />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )

  const SectionB = e => (
    <div className="flex flex-col h-full">
      {" "}
      {/* Ensure card content stretches to full height */}
      {e.fontello && (
        <i
          className={`${e?.fontello} text-left flex justify-start pb-5 mx-0 text-blue-500 icon `}
          style={{ fontSize: "70px" }}
        />
      )}
      {e.icon && (
        <div className={`${e.title ? "pb-5" : " "} h-52`}>
          <div className="w-full h-full rounded-3xl text-white overflow-hidden">
            <img
              src={e.icon?.mediaItemUrl}
              loading="lazy"
              height={e.icon?.mediaDetails?.height}
              width={e.icon?.mediaDetails?.width}
              alt={e.icon?.altText ? e.icon?.altText : e.title}
              // title={e.title}
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      )}
      {e.title && (
        <H3
          title={e.title}
          className={`font-bold text-left  ${e?.description ? "pb-5" : " "}`}
          textSize="text-lg"
        />
      )}
      {e.description && (
        <P title={e?.description} className={`text-left `} noPad />
      )}
      {e.bulletpoints && e.bulletpoints.length > 0 && (
        <div
          className="bullet-container mt-auto "
          style={{
            backgroundColor: "rgba(196, 199, 206, 0.2)",
            borderRadius: "8px",
          }}
        >
          {" "}
          {/* Push bullet points to the bottom */}
          <ul className="list-disc pl-5 space-y-2 m-4 text-shark-400">
            {e.bulletpoints.map((point, idx) => (
              <li key={idx} className="text-left">
                <span dangerouslySetInnerHTML={{ __html: point.subpoints }} />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )

  return (
    <div className="flex justify-center bg-blue-200 ">
      <div className="px-4 sm:px-6 max-w-7xl lg:px-12">
        <div
          className={`lg:grid lg:grid-cols-${size} lg:gap-6`}
          data-aos="fade-up"
        >
          {feature.map((e, index) => {
            const pointer = e.cta?.url ? "cursor-pointer" : "cursor-default"
            return theme ? (
              <div
                className={`shadow-lg text-center p-6 my-6 lg:my-0 bg-white rounded-3xl flex flex-col ${
                  e.icon
                    ? "transition border-white hover:shadow-xl hover:-translate-y-1"
                    : "transition duration-200 ease-in-out icon-card"
                }`}
                key={index}
              >
                {e.cta?.url ? (
                  <a
                    href={e.cta?.url ? e.cta.url : "#"}
                    className={`${pointer} flex flex-col h-full`}
                  >
                    {SectionA(e)}
                  </a>
                ) : (
                  <>{SectionA(e)}</>
                )}
              </div>
            ) : (
              <div
                className={`shadow-lg text-center p-6 my-6 lg:my-0 bg-white rounded-3xl flex flex-col ${
                  e.icon
                    ? "transition border-white hover:shadow-xl hover:-translate-y-1"
                    : "transition duration-200 ease-in-out icon-card"
                }`}
                key={index}
              >
                {e.cta?.url ? (
                  <a
                    href={e.cta?.url ? e.cta.url : "#"}
                    className={`${pointer} flex flex-col h-full`}
                  >
                    {SectionB(e)}
                  </a>
                ) : (
                  <>{SectionB(e)}</>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ImageWithPoints

// import React from "react"
// import P from "../../core/P"
// import H3 from "../../core/H3"
// import Icon from "../../core/Icon"

// export const ImageWithPoints = ({ feature, size, theme }) => {

//   const SectionA = e => (
//     <>
//       {e.fontello && (
//         <Icon
//           iconName={e.fontello}
//           className="text-left flex justify-start pb-5 mx-0"
//           theme={theme}
//         />
//       )}
//       {e.icon && (
//         <div className={`${e.title ? "pb-5" : " "} h-52`}>
//           <div className="w-full h-full rounded-3xl text-white overflow-hidden">
//             <img
//               src={e.icon?.mediaItemUrl}
//               loading="lazy"
//               height={e.icon?.mediaDetails?.height}
//               width={e.icon?.mediaDetails?.width}
//               alt={e.icon?.altText ? e.icon?.altText : e.title}
//               title={e.title}
//               className="object-cover w-full h-full"
//             />
//           </div>
//         </div>
//       )}

//       {e.title && (
//         <H3
//           title={e.title}
//           className={`font-bold text-left text-shark-400 ${
//             e.description ? "pb-5" : " "
//           }`}
//           textSize="text-lg"
//         />
//       )}
//       {e.description && (
//         <P title={e.description} className={`text-left text-shark-400`} noPad />
//       )}
//       {e.bulletpoints && e.bulletpoints.length > 0 && (
//         <div className="bullet-container">
//           <ul className="list-disc pl-5 space-y-2 mt-4 text-shark-400">
//             {e.bulletpoints.map((point, idx) => (
//               <li key={idx} className="text-left">
//                 <span
//                   dangerouslySetInnerHTML={{ __html: point.subpoints }}
//                 />
//               </li>
//             ))}
//           </ul>
//         </div>
//       )}
//     </>
//   )

//   const SectionB = e => (
//     <>
//       {e.fontello && (
//         <i
//           className={`${e?.fontello} text-left flex justify-start pb-5 mx-0 text-blue-500 icon `}
//           style={{ fontSize: "70px" }}
//         />
//       )}
//       {e.icon && (
//         <div className={`${e.title ? "pb-5" : " "} h-52`}>
//           <div className="w-full h-full rounded-3xl text-white overflow-hidden">
//             <img
//               src={e.icon?.mediaItemUrl}
//               loading="lazy"
//               height={e.icon?.mediaDetails?.height}
//               width={e.icon?.mediaDetails?.width}
//               alt={e.icon?.altText ? e.icon?.altText : e.title}
//               title={e.title}
//               className="object-cover w-full h-full"
//             />
//           </div>
//         </div>
//       )}

//       {e.title && (
//         <H3
//           title={e.title}
//           className={`font-bold text-left  ${e?.description ? "pb-5" : " "}`}
//           textSize="text-lg"
//         />
//       )}
//       {e.description && (
//         <P title={e?.description} className={`text-left `} noPad />
//       )}
//       {e.bulletpoints && e.bulletpoints.length > 0 && (
//         <div className="bullet-container">
//           <ul className="list-disc pl-5 space-y-2 mt-4 text-shark-400">
//             {e.bulletpoints.map((point, idx) => (
//               <li key={idx} className="text-left">
//                 <span
//                   dangerouslySetInnerHTML={{ __html: point.subpoints }}
//                 />
//               </li>
//             ))}
//           </ul>
//         </div>
//       )}
//     </>
//   )

//   return (
//     <div className="flex justify-center bg-blue-200">
//       <div className="px-4 sm:px-6 max-w-7xl lg:px-12">
//         <div
//           className={`lg:grid lg:grid-cols-${size} lg:gap-6`}
//           data-aos="fade-up"
//         >
//           {feature.map((e, index) => {
//             const pointer = e.cta?.url ? "cursor-pointer" : "cursor-default"
//             return theme ? (
//               <div
//                 className={`shadow-lg text-center p-6 my-6 lg:my-0 bg-white rounded-3xl ${
//                   e.icon
//                     ? "transition border-white hover:shadow-xl hover:-translate-y-1"
//                     : "transition duration-200 ease-in-out icon-card"
//                 }`}
//                 key={index}
//               >
//                 {e.cta?.url ? (
//                   <a
//                     href={e.cta?.url ? e.cta.url : "#"}
//                     className={`${pointer}`}
//                   >
//                     {SectionA(e)}
//                   </a>
//                 ) : (
//                   <>{SectionA(e)}</>
//                 )}
//               </div>
//             ) : (
//               <div
//                 className={`shadow-lg text-center p-6 my-6 lg:my-0 bg-white rounded-3xl ${
//                   e.icon
//                     ? "transition border-white hover:shadow-xl hover:-translate-y-1"
//                     : "transition duration-200 ease-in-out icon-card"
//                 }`}
//                 key={index}
//               >
//                 {e.cta?.url ? (
//                   <a
//                     href={e.cta?.url ? e.cta.url : "#"}
//                     className={`${pointer}`}
//                   >
//                     {SectionB(e)}
//                   </a>
//                 ) : (
//                   <>{SectionB(e)}</>
//                 )}
//               </div>
//             )
//           })}
//         </div>
//       </div>
//     </div>
//   )
// }

// export default ImageWithPoints
